@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-arr_down: "\EA01"
$icon-Vector: "\EA02"
$icon-ph: "\EA03"
$icon-mai: "\EA04"
$icon-mail: "\EA05"
$icon-close: "\EA06"
$icon-menu: "\EA07"
$icon-right-arrow: "\EA08"
$icon-clock: "\EA09"
$icon-none: "\EA10"
$icon-yes: "\EA11"
$icon-account: "\EA12"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-arr_down:before
	content: $icon-arr_down
.icon-Vector:before
	content: $icon-Vector
.icon-ph:before
	content: $icon-ph
.icon-mai:before
	content: $icon-mai
.icon-mail:before
	content: $icon-mail
.icon-close:before
	content: $icon-close
.icon-menu:before
	content: $icon-menu
.icon-right-arrow:before
	content: $icon-right-arrow
.icon-clock:before
	content: $icon-clock
.icon-none:before
	content: $icon-none
.icon-yes:before
	content: $icon-yes
.icon-account:before
	content: $icon-account
